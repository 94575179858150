import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/cloud/thingos-homepage/packages/meshlighting/src/components/BlogLayout.tsx";
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { BlogImageWrapper } from '../../components/BlogImageWrapper';
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		appImage: file(relativePath: { eq: "blog-posts/meshapp-screenshot.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
		livingroom: file(relativePath: { eq: "blog-posts/scene-livingroom.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
		diningroom: file(relativePath: { eq: "blog-posts/scene-diningroom.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
		cold: file(relativePath: { eq: "blog-posts/scene-cold.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
		warm: file(relativePath: { eq: "blog-posts/scene-warm.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
	}
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Die ThingOS Meshthings App bietet umfangreiche Kontrollmöglichkeiten für ihre Bluetooth® Mesh Geräte und Leuchten, einschließlich der Steuerung elektrischer Beschlagstechnik in Möbeln und Räumen. Nutzen Sie die Möglichkeit ihre Geräte in individuellen Gruppen zusammenzufassen und ihre favorisierten Einstellungen in Szenen abzuspeichern, um sie jederzeit durch einen einzigen Tap wieder aufrufen zu können.`}</p>
    <h4>{`🏡 BLUETOOTH® MESH NETZWERK ERSTELLEN`}</h4>
    <GatsbyImage image={getImage(props.data.appImage)} alt="Meshthings app" style={{
      marginBottom: '1.5rem',
      marginTop: '1rem'
    }} imgStyle={{
      borderRadius: '4px'
    }} mdxType="GatsbyImage" />
    <p>{`Die Meshthings App öffnet Ihnen die Tore zu ihrem eigenen Bluetooth® Mesh Netzwerk. Sie brauchen keinen zusätzlichen Hub, um ihr smartes Zuhause einzurichten.`}</p>
    <h4>{`💡 GERÄTE STEUERN`}</h4>
    <p>{`Steuern Sie die Helligkeit, Sättigung und Farbe ihrer Bluetooth® Mesh fähigen Leuchten. Neben Leuchten werden auch andere Geräte, wie zum Beispiel vernetzten Möbelbeschläge unterstützt.`}</p>
    <h4>{`🛋 GRUPPEN ERSTELLEN`}</h4>
    <BlogImageWrapper mdxType="BlogImageWrapper">
	<GatsbyImage image={getImage(props.data.diningroom)} alt="Esszimmer" style={{
        marginBottom: '1.5rem',
        marginTop: '1rem'
      }} imgStyle={{
        borderRadius: '4px'
      }} mdxType="GatsbyImage" />
	<GatsbyImage image={getImage(props.data.livingroom)} alt="Wohnzimmer" style={{
        marginBottom: '1.5rem',
        marginTop: '1rem'
      }} imgStyle={{
        borderRadius: '4px'
      }} mdxType="GatsbyImage" />
    </BlogImageWrapper>
    <p>{`Organisieren Sie all ihre Bluetooth® Mesh Geräte in individuellen Räumen und Gruppen und steuern Sie diese intuitiv von einem zentralen Interface.`}</p>
    <h4>{`🎬 SZENEN ANLEGEN`}</h4>
    <BlogImageWrapper mdxType="BlogImageWrapper">
	<GatsbyImage image={getImage(props.data.cold)} alt="Kaltweiß" style={{
        marginBottom: '1.5rem',
        marginTop: '1rem'
      }} imgStyle={{
        borderRadius: '4px'
      }} mdxType="GatsbyImage" />
	<GatsbyImage image={getImage(props.data.warm)} alt="Warmweiß" style={{
        marginBottom: '1.5rem',
        marginTop: '1rem'
      }} imgStyle={{
        borderRadius: '4px'
      }} mdxType="GatsbyImage" />
    </BlogImageWrapper>
    <p>{`Speichern Sie ihre detaillierten Lichteinstellungen als Szenen ab, um diese jederzeit durch einen einzigen Fingertipp wieder abrufen zu können. Das spart Zeit und Sie sind auf jeden Umstand vorbereitet.`}</p>
    <h4>{`🎛 FERNBEDIENUNGEN UND SENSOREN`}</h4>
    <p>{`Verbinden und konfigurieren Sie Fernbedienungen, Schalter und Sensoren, um Ihr Netzwerk auch ohne App steuern zu können. Sprechen Sie ihre Gruppen direkt mit ihrem Wandschalter an, ohne eine App starten zu müssen.`}</p>
    <h4>{`🔒 SICHERHEIT UND TEILEN`}</h4>
    <p>{`Ihr Bluetooth® Netzwerk ist gegen äußere Einflüsse gesichert und lässt nur ausgewählte Nutzer auf Ihr Netzwerk zugreifen. Sie allein entscheiden, wem Sie ihre Netzwerk teilen möchten. Ohne Cloud und sicher.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      